import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import apis from "../../apis";
import { logout } from "../../stores/authSlice";
import { handleCallApiError } from "../../errors";
import CustomMenu from "../CustomMenu";
import { Box, IconButton, MenuItem, Stack, Typography } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";

const User = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      await apis.auth.logout();
      dispatch(logout());
      toast.success("Đăng xuất thành công");
    } catch (error) {
      handleCallApiError(error);
    }
  };

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={0.5}
    >
      <Box>
        <IconButton onClick={handleMenu} color="inherit">
          <AccountCircle color="black" fontSize="large" />
        </IconButton>
        <CustomMenu anchorEl={anchorEl} onClose={handleClose}>
          <MenuItem onClick={handleClose}>Hồ sơ cá nhân</MenuItem>
          <MenuItem onClick={handleLogout}>Đăng xuất</MenuItem>
        </CustomMenu>
      </Box>
      <Typography fontSize="16px">{currentUser?.name}</Typography>
    </Stack>
  );
};

export default User;
