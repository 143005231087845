const NOTIFICATION_STATUS = {
  SEEN: "SEEN",
  NEW: "NEW",
};

const NOTIFICATION_TYPE = {
  DISTRIBUTOR_ORDER_CONFIRMED_BY_ASM: "distributor-order-confirmed-by-asm",
  DISTRIBUTOR_ORDER_CONFIRMED_BY_SALE_ADMIN:
    "distributor-order-confirmed-by-sale-admin",
  DISTRIBUTOR_ORDER_UPDATED_BY_ADMIN: "distributor-order-updated-by-admin",
  DISTRIBUTOR_ORDER_UPDATED_BY_WAREHOUSE:
    "distributor-order-updated-by-warehouse",
  DISTRIBUTOR_ORDER_COMPLETED: "distributor-order-completed",
  DISTRIBUTOR_ORDER_CREATED: "distributor-order-created",
  DISTRIBUTOR_ORDER_CANCELLED: "distributor-order-cancelled",
  DISTRIBUTOR_ORDER_SHIPPING: "distribution-order-shipping",
};

module.exports = { NOTIFICATION_STATUS, NOTIFICATION_TYPE };
