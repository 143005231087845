import dayjs from "dayjs";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import {
  TextField,
  Autocomplete,
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Button,
} from "@mui/material";

import apis from "../../apis";
import MainCard from "../../components/MainCard";
import PageTitle from "../../components/PageTitle";
import DatePickerRange from "../../components/PickDateRange";
import {
  ALL,
  INITIAL_PAGING as DEFAULT_PAGING,
  PAGE_TYPE,
} from "../../constants";
import {
  DISTRIBUTOR_ORDER_STATUS,
  DISTRIBUTOR_ORDER_STATUS_LABEL,
} from "../../constants/order";
import useSearchParams from "../../hooks/useSearchParams";
import { Add, Autorenew } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import DistributorOrderList from "./DistributorOrderList";
import CreateOrderDialog from "./CreateOrderDialog";

const INITIAL_PAGING = { ...DEFAULT_PAGING, limit: 20 };

const INIT_FILTER = {
  distributorId: null,
  startDate: dayjs().startOf("month"),
  endDate: dayjs().endOf("month"),
  status: ALL,
  region: ALL,
};

const DistributorOrder = ({ validStatus, path, showCreateButton = false }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [paging, setPaging] = useState(INITIAL_PAGING);
  const [filter, setFilter] = useState(INIT_FILTER);
  const [distributors, setDistributors] = useState([]);
  const [distributorOrders, setDistributorOrders] = useState([]);
  const [regions, setRegions] = useState([]);
  const [openCreateOrderDialog, setOpenCreateOrderDialog] = useState(false);
  const [categories, setCategories] = useState([]);

  const { addParams } = useSearchParams();

  const renderDistributorOptions = (id) => {
    const distributor = distributors.find((d) => d.id === id);
    if (!distributor) return "";
    return `${distributor.code}. ${
      distributor.metadata?.name || distributor.name
    }`;
  };

  const handleChangePage = (page) => {
    setPaging((prev) => ({ ...prev, page }));
    addParams({ page });
  };

  const handleChangeDistributorId = (newValue) => {
    setFilter((prev) => ({
      ...prev,
      distributorId: newValue,
    }));
    setPaging(INITIAL_PAGING);
    addParams({
      page: INITIAL_PAGING.page,
      distributorId: newValue,
    });
  };

  const handleChangeStatus = (event) => {
    const { value } = event.target;
    setFilter((prev) => ({ ...prev, status: value }));
    setPaging(INITIAL_PAGING);
    addParams({
      page: INITIAL_PAGING.page,
      status: value === ALL ? "" : value,
    });
  };

  const handleChangeRegion = (event) => {
    const { value } = event.target;
    setFilter((prev) => ({ ...prev, region: value }));
    setPaging(INITIAL_PAGING);
    addParams({
      region: value === ALL ? "" : value,
      page: INITIAL_PAGING.page,
    });
  };

  const handleChangeDate = (newValue) => {
    setFilter((prev) => ({
      ...prev,
      startDate: newValue[0],
      endDate: newValue[1],
    }));
    setPaging(INITIAL_PAGING);
    addParams({
      page: INITIAL_PAGING.page,
      startDate: dayjs(newValue[0]).format("YYYY-MM-DD"),
      endDate: dayjs(newValue[1]).format("YYYY-MM-DD"),
    });
  };

  const handleRefresh = () => {
    setFilter((prev) => ({
      ...prev,
      startDate: INIT_FILTER.startDate,
      endDate: INIT_FILTER.endDate,
    }));
    setPaging(INITIAL_PAGING);
    addParams({
      page: INITIAL_PAGING.page,
      startDate: dayjs(INIT_FILTER.startDate).format("YYYY-MM-DD"),
      endDate: dayjs(INIT_FILTER.endDate).format("YYYY-MM-DD"),
    });
  };

  const fetchDistributors = async () => {
    try {
      const condition = {
        pageType: PAGE_TYPE.DISTRIBUTOR,
        sort: ["code_asc"],
      };

      const { result = [] } = await apis.user.getUsers(condition);
      setDistributors(result);
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const fetchRegions = async () => {
    try {
      const { result } = await apis.region.getRegions();
      setRegions(result.map((r) => r.name));
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const fetchCategories = async () => {
    try {
      const { result } = await apis.category.getCategories();
      setCategories(result);
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const fetchDistributorOrders = async (newFilter) => {
    setLoading(true);
    try {
      const condition = {
        limit: paging.limit,
        page: newFilter.page,
        startDate: dayjs(newFilter.startDate).startOf("day").format(),
        endDate: dayjs(newFilter.endDate).endOf("day").format(),
        distributorId: newFilter.distributorId || undefined,
        status:
          newFilter.status === ALL
            ? validStatus
              ? validStatus.join(",")
              : undefined
            : newFilter.status,
        region: newFilter.region === ALL ? undefined : newFilter.region,
      };

      const { result } = await apis.distributorOrder.getDistributorOrders(
        condition
      );
      setDistributorOrders(result.items);
      setPaging((prev) => ({ ...prev, total: result.total }));
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  useEffect(() => {
    const searchParams = queryString.parse(location.search);
    const {
      page = INITIAL_PAGING.page,
      distributorId = INIT_FILTER.distributorId,
      startDate = dayjs(INIT_FILTER.startDate).format("YYYY-MM-DD"),
      endDate = dayjs(INIT_FILTER.endDate).format("YYYY-MM-DD"),
      status = INIT_FILTER.status,
      region = INIT_FILTER.region,
    } = searchParams;
    setFilter({ distributorId, startDate, endDate, status, region });
    setPaging((prev) => ({ ...prev, page: parseInt(page, 10) }));
    fetchDistributorOrders({
      distributorId,
      startDate,
      endDate,
      status,
      region,
      page: parseInt(page, 10),
    });
  }, [location.search]);

  useEffect(() => {
    fetchDistributors();
    fetchRegions();
    fetchCategories();
  }, []);

  return (
    <>
      <PageTitle title={t("distributorOrder")} />
      <MainCard>
        <Grid container spacing={2} marginBottom={2}>
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <Autocomplete
              size="small"
              value={filter.distributorId}
              options={distributors.map((distributor) => distributor.id)}
              getOptionLabel={(option) => renderDistributorOptions(option)}
              filterSelectedOptions
              onChange={(event, newValue) =>
                handleChangeDistributorId(newValue)
              }
              renderOption={(props, key) => (
                <MenuItem value={key} {...props}>
                  {renderDistributorOptions(key)}
                </MenuItem>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  placeholder={t("distributor")}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                {t("status")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                value={filter.status}
                onChange={handleChangeStatus}
                size="small"
                label={t("status")}
                fullWidth
              >
                <MenuItem value={ALL}>{t("all")}</MenuItem>
                {Object.values(DISTRIBUTOR_ORDER_STATUS)
                  .filter(
                    (value) => !validStatus || validStatus.includes(value)
                  )
                  .map((value) => (
                    <MenuItem key={value} value={value}>
                      {t(DISTRIBUTOR_ORDER_STATUS_LABEL[value])}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label-1">
                {t("region")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label-1"
                value={filter.region}
                onChange={handleChangeRegion}
                size="small"
                label={t("region")}
                fullWidth
              >
                <MenuItem value={ALL}>{t("all")}</MenuItem>
                {regions.map((region) => (
                  <MenuItem key={region} value={region}>
                    {t("region")} {region}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={5}>
            <Box display="flex">
              <DatePickerRange
                value={[filter.startDate, filter.endDate]}
                onChange={handleChangeDate}
              />
              <Tooltip title={t("refresh")}>
                <IconButton aria-label="refresh" onClick={handleRefresh}>
                  <Autorenew />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            >
              {showCreateButton && (
                <Button
                  variant="contained"
                  startIcon={<Add />}
                  onClick={() => setOpenCreateOrderDialog(true)}
                >
                  {t("createOrder")}
                </Button>
              )}
            </Stack>
          </Grid>
        </Grid>
        <DistributorOrderList
          loading={loading}
          paging={paging}
          handleChangePage={handleChangePage}
          distributors={distributors}
          distributorOrders={distributorOrders}
          path={path}
        />
      </MainCard>
      <CreateOrderDialog
        open={openCreateOrderDialog}
        handleClose={() => setOpenCreateOrderDialog(false)}
        distributors={distributors.filter(
          (distributor) => distributor.active && !distributor.stopCooperatedAt
        )}
        categories={categories}
      />
    </>
  );
};

export default DistributorOrder;
