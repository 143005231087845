import { Add, Search } from "@mui/icons-material";
import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import apis from "../../apis";
import MainCard from "../../components/MainCard";
import PageTitle from "../../components/PageTitle";
import { ALL, DIALOG_TYPE, INITIAL_PAGING } from "../../constants";
import PaymentAccountList from "./PaymentAccountList";
import PaymentAccountDialog from "./PaymentAccountDialog";

const INIT_FILTER = {
  search: "",
  bankBin: ALL,
};

const INIT_PAYMENT_ACCOUNT = {
  bankBin: "",
  bankHolder: "",
  bankNumber: "",
};

const PaymentAccount = () => {
  const { t } = useTranslation();

  const [filter, setFilter] = useState(INIT_FILTER);
  const [paging, setPaging] = useState(INITIAL_PAGING);
  const [banks, setBanks] = useState([]);
  const [paymentAccounts, setPaymentAccounts] = useState([]);
  const [loading, setLoading] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogType, setDialogType] = useState("");
  const [paymentAccount, setPaymentAccount] = useState(INIT_PAYMENT_ACCOUNT);
  const [reload, setReload] = useState(false);

  const handleChangeFilter = (field, value) => {
    setFilter((prev) => ({ ...prev, [field]: value }));
  };

  const handleOpenDialog = (dt, account) => {
    setDialogType(dt);
    if (account) {
      setPaymentAccount(account);
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setPaymentAccount(INIT_PAYMENT_ACCOUNT);
  };

  const fetchPaymentAccounts = async () => {
    setLoading(true);
    try {
      const response = await apis.paymentAccount.getPaymentAccounts({
        search: filter.search || undefined,
        searchFields: ["bankHolder", "bankNumber"],
        bankBin: filter.bankBin === ALL ? undefined : filter.bankBin,
        offset: (paging.page - 1) * paging.limit,
        limit: paging.limit,
      });
      if (response.status === 1) {
        setPaymentAccounts(response?.result?.paymentAccounts || []);
        setPaging((prev) => ({ ...prev, total: response?.result?.total || 0 }));
      }
    } catch (error) {
      toast.error(t("fetchPaymentAccountError"));
    }
    setLoading(false);
  };

  const fetchBanks = async () => {
    try {
      const response = await apis.paymentAccount.getBanks();
      if (response.status === 1) {
        setBanks(response?.result || []);
      }
    } catch (error) {
      toast.error(t("fetchBankError"));
    }
  };

  useEffect(() => {
    fetchBanks();
  }, []);

  useEffect(() => {
    fetchPaymentAccounts();
    setPaging((prev) => ({ ...prev, page: 1 }));
  }, [filter.search, filter.bankBin, paging.page, reload]);

  return (
    <>
      <PageTitle title={t("paymentAccount")} />
      <MainCard>
        <Grid container spacing={2} marginBottom={2}>
          <Grid item xs={12} sm={8} md={8} lg={4}>
            <TextField
              value={filter.search}
              size="small"
              fullWidth
              placeholder={t("paymentAccountSearch")}
              onChange={(e) => handleChangeFilter("search", e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={2}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">{t("bank")}</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                value={filter.bankBin}
                onChange={(e) => handleChangeFilter("bankBin", e.target.value)}
                size="small"
                label={t("bank")}
                fullWidth
              >
                <MenuItem value={ALL}>{t("all")}</MenuItem>
                {banks.map((bank) => (
                  <MenuItem key={bank.id} value={bank.bin}>
                    {bank.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={2}></Grid>
          <Grid item xs={12} sm={8} md={8} lg={4}>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            >
              <Button
                variant="contained"
                startIcon={<Add />}
                onClick={() => handleOpenDialog(DIALOG_TYPE.CREATE)}
              >
                {t("addPaymentAccount")}
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <PaymentAccountList
          paymentAccounts={paymentAccounts}
          paging={paging}
          loading={loading}
          handleChangePage={(page) => setPaging((prev) => ({ ...prev, page }))}
          handleOpenDialog={handleOpenDialog}
          refetch={fetchPaymentAccounts}
        />
      </MainCard>

      <PaymentAccountDialog
        open={openDialog}
        handleClose={handleCloseDialog}
        dialogType={dialogType}
        initPaymentAccount={paymentAccount}
        banks={banks}
        reload={() => setReload((prev) => !prev)}
      />
    </>
  );
};

export default PaymentAccount;
