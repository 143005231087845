const SYSTEM_ERROR_CODE = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  TOO_MANY_REQUESTS: 429,
  INTERNAL_SERVER_ERROR: 500,
};

const VELA_AUTH_ERROR_CODE = {
  GROUP_PERMISSION_EXIST: 1000,
  GROUP_PERMISSION_NOT_FOUND: 1001,
  GROUP_PERMISSION_HAS_PERMISSIONS: 1002,

  PERMISSION_EXIST: 1010,
  PERMISSION_NOT_FOUND: 1011,

  ROLE_EXIST: 1020,
  ROLE_NOT_FOUND: 1021,
  ROLE_ASSIGNED_FOR_USER: 1022,

  CREDENTIAL_INVALID: 1030,
  USER_NOT_ACTIVE: 1031,
  USER_NOT_FOUND: 1032,
  USER_EXIST: 1033,
};

const VELA_API_ERROR_CODE = {
  PRODUCT_EXIST: 2060,
  PRODUCT_NOT_FOUND: 2061,

  CATEGORY_EXIST: 2070,
  CATEGORY_NOT_FOUND: 2071,
  CATEGORY_IN_USE: 2072,
  CATEGORY_INACTIVE: 2073,
};

export { SYSTEM_ERROR_CODE, VELA_AUTH_ERROR_CODE, VELA_API_ERROR_CODE };
