import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import {
  Autocomplete,
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import { Add, DownloadOutlined, Search } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

import apis from "../../apis";
import { QR_TYPE } from "../../constants/qr";
import DistributorList from "./DistributorList";
import PageTitle from "../../components/PageTitle";
import MainCard from "../../components/MainCard";
import QrDialog from "../../components/QrDialog";
import { INITIAL_PAGING, MINI_APP_ROLE_KEY, PAGE_TYPE } from "../../constants";
import debounce from "../../utils/debounce";
import useSearchParams from "../../hooks/useSearchParams";
import Popup from "../../components/Popup";
import { downloadFile } from "../../utils/download";

const INIT_FILTER = {
  search: "",
  asmId: null,
  supervisorId: null,
  province: null,
};

const Distributor = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const accessToken = useSelector((state) => state.auth.accessToken);

  const [filter, setFilter] = useState(INIT_FILTER);
  const [paging, setPaging] = useState(INITIAL_PAGING);
  const [reload, setReload] = useState(false);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openQrDialog, setOpenQrDialog] = useState(false);
  const [qrType, setQrType] = useState(null);
  const [openChangeStatusPopup, setOpenChangeStatusPopup] = useState(false);
  const [distributor, setDistributor] = useState({});
  const [downloading, setDownloading] = useState(false);
  const [asms, setAsms] = useState([]);
  const [supervisors, setSupervisors] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [openStopCooperatePopup, setStopCooperatePopup] = useState(false);

  const { addParams } = useSearchParams();

  const renderUserOptions = (id, type) => {
    let user;
    if (type === MINI_APP_ROLE_KEY.ASM)
      user = asms.find((user) => user.id === id);
    if (type === MINI_APP_ROLE_KEY.SUPERVISOR)
      user = supervisors.find((user) => user.id === id);
    if (!user) return "";
    return `${user.metadata?.name || user.name} - ${user.phoneNumber}`;
  };

  const handleChangePage = (page) => {
    setPaging((prev) => ({ ...prev, page }));
    addParams({ page });
  };

  const onChangeSearch = (value) => {
    setPaging((prev) => ({ ...prev, page: INITIAL_PAGING.page }));
    addParams({ search: value, page: INITIAL_PAGING.page });
  };

  const handleChangeSearch = (event) => {
    const { value } = event.target;
    setFilter((prev) => ({ ...prev, search: value }));
    debounce(onChangeSearch, 1000)(value);
  };

  const handleChangeAsmId = (newValue) => {
    setFilter((prev) => ({
      ...prev,
      asmId: newValue,
    }));
    setPaging(INITIAL_PAGING);
    addParams({
      page: INITIAL_PAGING.page,
      asmId: newValue,
    });
  };

  const handleChangeSupervisorId = (newValue) => {
    setFilter((prev) => ({
      ...prev,
      supervisorId: newValue,
    }));
    setPaging(INITIAL_PAGING);
    addParams({
      page: INITIAL_PAGING.page,
      supervisorId: newValue,
    });
  };

  const handleChangeProvince = (newValue) => {
    setFilter((prev) => ({
      ...prev,
      province: newValue,
    }));
    setPaging(INITIAL_PAGING);
    addParams({
      page: INITIAL_PAGING.page,
      province: newValue,
    });
  };

  const handleOpenQrDialog = () => {
    setQrType(QR_TYPE.CREATE_DISTRIBUTOR);
    setOpenQrDialog(true);
  };

  const handleCloseQrDialog = () => {
    setOpenQrDialog(false);
    setQrType(null);
  };

  const handleOpenChangeStatusPopup = (currDistributor) => {
    setDistributor(currDistributor);
    setOpenChangeStatusPopup(true);
  };

  const handleCloseChangeStatusPopup = () => {
    setOpenChangeStatusPopup(false);
  };

  const handleOpenStopCooperatePopup = (row) => {
    setDistributor(row);
    setStopCooperatePopup(true);
  };

  const handleCloseStopCooperatePopup = () => {
    setStopCooperatePopup(false);
    setDistributor({});
  };

  const handleReload = () => {
    setReload((prev) => !prev);
  };

  const handleChangeStatus = async () => {
    try {
      const res = await apis.user.changeStatus(
        distributor.id,
        !distributor.active
      );
      if (!res) throw new Error();
      toast.success(
        distributor.active ? t("lockAccountSuccess") : t("unlockAccountSuccess")
      );
      handleReload();
    } catch (error) {
      toast.error(t(error.message));
    }
    setDistributor({});
  };

  const handleStopCooperate = async () => {
    try {
      const res = await apis.user.stopCooperate(distributor.id);
      if (!res) throw new Error();
      toast.success(t("stopCooperateSuccess"));
      handleReload();
    } catch (error) {
      toast.error(t(error.message));
    }
    setDistributor({});
  };

  const getRegion = (asmId) => {
    const asm = asms.find((u) => u.id === asmId);
    return asm?.region || undefined;
  };

  const handleDownload = async () => {
    setDownloading(true);
    try {
      const searchParams = queryString.parse(location.search);
      const {
        search = INIT_FILTER.search,
        roleId = INIT_FILTER.roleId,
        province = INIT_FILTER.province,
        supervisorId = INIT_FILTER.supervisorId,
        asmId = INIT_FILTER.asmId,
      } = searchParams;

      await downloadFile({
        url: "/users/download",
        params: {
          search: search || undefined,
          province: province || undefined,
          supervisorId: supervisorId || undefined,
          region: getRegion(asmId),
          pageType: roleId ? undefined : PAGE_TYPE.DISTRIBUTOR,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        fileName: `distributors_${dayjs().unix()}.xlsx`,
      });
    } catch (error) {
      toast.error(t(error.message));
    }

    setDownloading(false);
  };

  const fetchUsers = async () => {
    try {
      const [res1, res2] = await Promise.all([
        apis.user.getUsers({
          pageType: PAGE_TYPE.ASM,
        }),
        apis.user.getUsers({
          pageType: PAGE_TYPE.SUPERVISOR,
        }),
      ]);
      setAsms(res1.result);
      setSupervisors(res2.result);
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const fetchProvinces = async () => {
    try {
      const res = await apis.address.getVnUnits();
      setProvinces(res.result);
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const fetchDistributors = async (newFilter) => {
    setLoading(true);
    try {
      const condition = {
        search: newFilter.search || undefined,
        limit: paging.limit,
        page: newFilter.page,
        pageType: PAGE_TYPE.DISTRIBUTOR,
        province: newFilter.province || undefined,
        supervisorId: newFilter.supervisorId || undefined,
        region: getRegion(newFilter.asmId),
        sort: ["code_asc"],
      };

      const { result } = await apis.user.getUsers(condition);
      setUsers(result.users);
      setPaging((prev) => ({ ...prev, total: result.total }));
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  useEffect(() => {
    const searchParams = queryString.parse(location.search);
    const {
      search = INIT_FILTER.search,
      asmId = INIT_FILTER.asmId,
      supervisorId = INIT_FILTER.supervisorId,
      province = INIT_FILTER.province,
      page = INITIAL_PAGING.page,
    } = searchParams;
    setFilter({ search, asmId, supervisorId, province });
    setPaging((prev) => ({ ...prev, page: parseInt(page, 10) }));
    if (asms.length)
      fetchDistributors({
        search,
        asmId,
        supervisorId,
        province,
        page: parseInt(page, 10),
      });
  }, [location.search, reload, asms]);

  useEffect(() => {
    fetchUsers();
    fetchProvinces();
  }, []);

  return (
    <>
      <PageTitle title={t("distributor")} />
      <MainCard>
        <Grid container spacing={2} marginBottom={2}>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <TextField
              value={filter.search}
              size="small"
              fullWidth
              placeholder={t("distributorSearch")}
              onChange={handleChangeSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Autocomplete
              size="small"
              value={filter.asmId}
              options={asms.map((asm) => asm.id)}
              getOptionLabel={(option) =>
                renderUserOptions(option, MINI_APP_ROLE_KEY.ASM)
              }
              filterSelectedOptions
              onChange={(event, newValue) => handleChangeAsmId(newValue)}
              renderOption={(props, key) => (
                <MenuItem value={key} {...props}>
                  {renderUserOptions(key, MINI_APP_ROLE_KEY.ASM)}
                </MenuItem>
              )}
              renderInput={(params) => (
                <TextField {...params} fullWidth placeholder="ASM" />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Autocomplete
              size="small"
              value={filter.supervisorId}
              options={supervisors.map((supervisor) => supervisor.id)}
              getOptionLabel={(option) =>
                renderUserOptions(option, MINI_APP_ROLE_KEY.SUPERVISOR)
              }
              filterSelectedOptions
              onChange={(event, newValue) => handleChangeSupervisorId(newValue)}
              renderOption={(props, key) => (
                <MenuItem value={key} {...props}>
                  {renderUserOptions(key, MINI_APP_ROLE_KEY.SUPERVISOR)}
                </MenuItem>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  placeholder={t("supervisor")}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2} lg={2}>
            <Autocomplete
              size="small"
              value={filter.province || null}
              options={provinces.map((province) => province.name)}
              onChange={(event, newValue) => handleChangeProvince(newValue)}
              renderOption={(props, key) => (
                <MenuItem value={key} {...props}>
                  {key}
                </MenuItem>
              )}
              renderInput={(params) => (
                <TextField {...params} fullWidth placeholder={t("province")} />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            >
              <Button
                variant="contained"
                startIcon={<Add />}
                onClick={handleOpenQrDialog}
              >
                {t("addDistributor")}
              </Button>
              <LoadingButton
                loading={downloading}
                loadingPosition="start"
                variant="outlined"
                startIcon={<DownloadOutlined />}
                disabled={!users.length}
                onClick={handleDownload}
              >
                {t("download")}
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
        <DistributorList
          loading={loading}
          paging={paging}
          handleChangePage={handleChangePage}
          distributors={users}
          asms={asms}
          supervisors={supervisors}
          handleOpenChangeStatusPopup={handleOpenChangeStatusPopup}
          handleOpenStopCooperatePopup={handleOpenStopCooperatePopup}
        />
      </MainCard>
      <QrDialog
        open={openQrDialog}
        handleClose={handleCloseQrDialog}
        qrType={qrType}
      />
      <Popup
        open={openChangeStatusPopup}
        onClose={handleCloseChangeStatusPopup}
        onOk={handleChangeStatus}
        okMessage={t("accept")}
        content={
          distributor.active
            ? t("areYouSureLockAccount", {
                name: distributor?.metadata?.name || distributor.name,
                phoneNumber: distributor.phoneNumber,
              })
            : t("areYouSureUnlockAccount", {
                name: distributor?.metadata?.name || distributor.name,
                phoneNumber: distributor.phoneNumber,
              })
        }
        title={distributor.active ? t("lockAccount") : t("unlockAccount")}
      />
      <Popup
        open={openStopCooperatePopup}
        onClose={handleCloseStopCooperatePopup}
        onOk={handleStopCooperate}
        okMessage={t("accept")}
        content={t("areYouSureStopCooperate", {
          name: distributor?.metadata?.name || distributor.name,
          phoneNumber: distributor.phoneNumber,
        })}
        title={t("stopCooperate")}
      />
    </>
  );
};

export default Distributor;
