import { Block, ThumbUpAltOutlined } from "@mui/icons-material";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import apis from "../../apis";
import ImagePreview from "../../components/ImagePreview";
import Table from "../../components/Table";
import {
  PAYMENT_METHOD,
  TRANSACTION_STATUS,
  WALLET_TYPE,
} from "../../constants/wallet";
import { getPaymentQrLink } from "../../services/paymentQr";
import { COLOR } from "../../styles/color";
import Popup from "../../components/Popup";
import { useState } from "react";
import moment from "moment";

const POPUP_TYPE = {
  CONFIRM_TRANSACTION: "confirm",
  CANCEL_TRANSACTION: "cancel",
};

const TransactionList = ({
  loading,
  paging,
  handleChangePage,
  transactions,
  reload,
}) => {
  const { t } = useTranslation();

  const [popup, setPopup] = useState({
    open: false,
    type: "",
  });
  const [transaction, setTransaction] = useState({});

  const handleOpenPopup = (type, row) => {
    setTransaction(row);
    setPopup({ open: true, type });
  };

  const handleClosePopup = () => {
    setPopup({ open: false, type: "" });
  };

  const handleConfirmTransaction = async () => {
    try {
      await apis.transaction.confirmTransaction(transaction?.id);
      reload();
      toast.success(t("confirmTransactionSuccess"));
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const handleCancelTransaction = async () => {
    try {
      await apis.transaction.cancelTransaction(transaction?.id);
      reload();
      toast.success(t("cancelTransactionSuccess"));
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const renderTransactionStatus = (status) => {
    switch (status) {
      case TRANSACTION_STATUS.PENDING:
        return <Typography color={COLOR.warning.base}>{t(status)}</Typography>;
      case TRANSACTION_STATUS.FAILED:
        return <Typography color={COLOR.error.base}>{t(status)}</Typography>;
      case TRANSACTION_STATUS.CANCELED:
        return <Typography color={COLOR.error.base}>{t(status)}</Typography>;
      case TRANSACTION_STATUS.COMPLETED:
        return <Typography color={COLOR.success.base}>{t(status)}</Typography>;
      default:
        return <Typography>{t(status)}</Typography>;
    }
  };

  const renderTransactionBankAccount = (row) => {
    switch (row.paymentMethod) {
      case PAYMENT_METHOD.BANK:
        return (
          <div>
            <Typography>{row.paymentAccount?.bankNumber}</Typography>
            <Typography>{row.paymentAccount?.bankName || "-"}</Typography>
          </div>
        );
      case PAYMENT_METHOD.CASH:
        return <Typography>{t("cash")}</Typography>;
      default:
        return <Typography>-</Typography>;
    }
  };

  const columns = [
    {
      title: t("walletType"),
      align: "left",
      sortable: true,
      render: (row) => (
        <Typography>
          {row.walletType === WALLET_TYPE.PAYMENT
            ? t("paymentWallet")
            : t("commodityCompensationWallet")}
        </Typography>
      ),
    },
    {
      title: t("sourceWallet"),
      align: "left",
      sortable: true,
      render: (row) => <Typography>{row.user?.metadata?.name}</Typography>,
    },
    {
      title: t("totalMoney"),
      align: "right",
      sortable: true,
      render: (row) => (
        <Typography>{row.amount?.toLocaleString()} đ</Typography>
      ),
    },
    {
      title: t("createdAt"),
      align: "center",
      sortable: true,
      width: "135px",
      render: (row) => moment(row.createdAt).format("DD/MM/YYYY HH:mm"),
    },
    {
      title: t("completedAt"),
      align: "center",
      sortable: true,
      width: "135px",
      render: (row) =>
        row.completedAt
          ? moment(row.completedAt).format("DD/MM/YYYY HH:mm")
          : "-",
    },
    {
      title: t("status"),
      align: "left",
      sortable: true,
      render: (row) => renderTransactionStatus(row.status),
    },
    {
      title: t("note"),
      align: "left",
      sortable: true,
      field: "memo",
      width: "100px",
    },
  ];

  return (
    <>
      <Table
        data={transactions}
        columns={columns}
        total={paging.total}
        page={paging.page}
        loading={loading}
        onChangePage={handleChangePage}
        showNumber={true}
      />
      <Popup
        open={popup.open}
        onClose={handleClosePopup}
        onOk={
          popup.type === POPUP_TYPE.CONFIRM_TRANSACTION
            ? handleConfirmTransaction
            : handleCancelTransaction
        }
        okMessage={t("accept")}
        content={
          popup.type === POPUP_TYPE.CONFIRM_TRANSACTION
            ? t("areYouSureConfirmTransaction", {
                amount: transaction?.amount?.toLocaleString(),
                walletType:
                  transaction?.walletType === WALLET_TYPE.PAYMENT
                    ? t("paymentWallet")
                    : t("commodityCompensationWallet"),
                distributor: transaction?.user?.name,
              })
            : t("areYouSureCancelTransaction", {
                amount: transaction?.amount?.toLocaleString(),
                walletType:
                  transaction?.walletType === WALLET_TYPE.PAYMENT
                    ? t("paymentWallet")
                    : t("commodityCompensationWallet"),
                distributor: transaction?.user?.name,
              })
        }
        title={
          popup.type === POPUP_TYPE.CONFIRM_TRANSACTION
            ? t("confirmTransaction")
            : t("cancelTransaction")
        }
      />
    </>
  );
};

export default TransactionList;
