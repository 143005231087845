import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  DialogContent,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import apis from "../../apis";
import CurrencyInput from "../../components/CurrencyInput";
import Dialog from "../../components/Dialog";
import { PAGE_TYPE } from "../../constants";
import {
  PAYMENT_METHOD,
  TRANSACTION_TYPE,
  WALLET_TYPE,
} from "../../constants/wallet";
import { COLOR } from "../../styles/color";
import { validateRequired as checkRequired } from "../../utils/validate";

const INIT_TRANSACTION = {
  walletType: "",
  distributorId: null,
  amount: "",
  reason: "",
  paymentAccountId: null,
  paymentMethod: "",
};

const PAYMENT_METHOD_MAPPING = {
  [PAYMENT_METHOD.BANK]: "transferBank",
  [PAYMENT_METHOD.CASH]: "cash",
};

const CreateTransactionDialog = ({ open, onClose, reload }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [distributors, setDistributors] = useState([]);
  const [paymentAccounts, setPaymentAccounts] = useState([]);
  const [transaction, setTransaction] = useState(INIT_TRANSACTION);
  const [transactionError, setTransactionError] = useState(INIT_TRANSACTION);

  const renderDistributorOptions = (id) => {
    const distributor = distributors.find((d) => d.id === id);
    if (!distributor) return "";
    return `${distributor.metadata?.name || distributor.name}`;
  };

  const handleCloseDialog = () => {
    setTransaction(INIT_TRANSACTION);
    setTransactionError(INIT_TRANSACTION);
    onClose();
  };

  const handleChangeTransaction = (event) => {
    const { name: field, value } = event.target;
    setTransaction((prev) => ({ ...prev, [field]: value }));
    setTransactionError((prev) => ({ ...prev, [field]: "" }));
  };

  const handleChangeDistributorId = (newValue) => {
    setTransaction((prev) => ({ ...prev, distributorId: newValue }));
    setTransactionError((prev) => ({ ...prev, distributorId: "" }));
  };

  const validateRequired = (field) => {
    if (!checkRequired(transaction[field])) {
      setTransactionError((prev) => ({ ...prev, [field]: t("fieldRequired") }));
      return false;
    }
    return true;
  };

  const validateValidNumber = (field) => {
    const value = +transaction[field];
    if (value < 1) {
      setTransactionError((prev) => ({ ...prev, [field]: t("dataInvalid") }));
      return false;
    }
    return true;
  };

  const validate = () => {
    const validateResult =
      validateRequired("walletType") &&
      validateRequired("distributorId") &&
      validateRequired("amount") &&
      validateValidNumber("amount");

    return validateResult;
  };

  const handleCreate = async () => {
    if (!validate()) return;

    setLoading(true);
    try {
      await apis.transaction.createTransaction({
        walletType: transaction.walletType,
        userId: transaction.distributorId,
        amount: transaction.amount,
        memo: transaction.reason || undefined,
        type: TRANSACTION_TYPE.WITHDRAW,
        paymentMethod: PAYMENT_METHOD.CASH,
      });

      setTransaction(INIT_TRANSACTION);
      reload();
      handleCloseDialog();
      toast.success(t("createWithdrawTicketSuccess"));
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  const fetchDistributors = async () => {
    try {
      const { result } = await apis.user.getUsers({
        pageType: PAGE_TYPE.DISTRIBUTOR,
        active: true,
        isStopCooperate: false,
        sort: ["code_asc"],
      });
      setDistributors(result);
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const fetchPaymentAccounts = async () => {
    try {
      const { result } = await apis.paymentAccount.getPaymentAccounts();
      setPaymentAccounts(result?.paymentAccounts || []);
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  useEffect(() => {
    fetchDistributors();
    fetchPaymentAccounts();
  }, []);

  return (
    <>
      <Dialog
        title={t("createWithdrawTicket")}
        maxWidth="sm"
        open={open}
        onClose={handleCloseDialog}
      >
        <DialogContent>
          <Box mb={3}>
            <Typography fontWeight={500} mb={1}>
              {t("walletType")}{" "}
              <span style={{ color: COLOR.error.base }}>*</span>:
            </Typography>
            <FormControl fullWidth error={!!transactionError.walletType}>
              <Select
                value={transaction.walletType}
                onChange={handleChangeTransaction}
                onBlur={() => validateRequired("walletType")}
                size="small"
                name="walletType"
              >
                <MenuItem key={WALLET_TYPE.PAYMENT} value={WALLET_TYPE.PAYMENT}>
                  {t("paymentWallet")}
                </MenuItem>
                <MenuItem
                  key={WALLET_TYPE.COMMODITY_COMPENSATION}
                  value={WALLET_TYPE.COMMODITY_COMPENSATION}
                >
                  {t("commodityCompensationWallet")}
                </MenuItem>
                <MenuItem
                  key={WALLET_TYPE.FREIGHT_COMPENSATION}
                  value={WALLET_TYPE.FREIGHT_COMPENSATION}
                >
                  {t("freightCompensationWallet")}
                </MenuItem>
                <MenuItem key={WALLET_TYPE.BONUS} value={WALLET_TYPE.BONUS}>
                  {t("bonusWallet")}
                </MenuItem>
              </Select>
              <FormHelperText>{transactionError.walletType}</FormHelperText>
            </FormControl>
          </Box>
          <Box mb={3}>
            <Typography fontWeight={500} mb={1}>
              {t("sourceWallet")}{" "}
              <span style={{ color: COLOR.error.base }}>*</span>:
            </Typography>
            <Autocomplete
              size="small"
              value={transaction.distributorId}
              options={distributors.map((distributor) => distributor.id)}
              getOptionLabel={(option) => renderDistributorOptions(option)}
              filterSelectedOptions
              onChange={(event, newValue) =>
                handleChangeDistributorId(newValue)
              }
              onBlur={() => validateRequired("distributorId")}
              renderOption={(props, key) => (
                <MenuItem value={key} {...props}>
                  {renderDistributorOptions(key)}
                </MenuItem>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!transactionError.distributorId}
                  helperText={transactionError.distributorId}
                  fullWidth
                />
              )}
            />
          </Box>
          <Box mb={3}>
            <Typography fontWeight={500} mb={1}>
              {t("amountOfMoney")} (&#8363;){" "}
              <span style={{ color: COLOR.error.base }}>*</span>:
            </Typography>
            <CurrencyInput
              customInput={TextField}
              allowDecimals={false}
              allowNegativeValue={false}
              fullWidth
              value={transaction.amount}
              onChange={handleChangeTransaction}
              onBlur={() =>
                validateRequired("amount") && validateValidNumber("amount")
              }
              error={!!transactionError.amount}
              helperText={transactionError.amount}
              name="amount"
            />
          </Box>
          <Box mb={3}>
            <Typography fontWeight={500} mb={1}>
              {t("reason")}:
            </Typography>
            <TextField
              helperText={transactionError.reason}
              error={!!transactionError.reason}
              value={transaction.reason}
              size="small"
              name="reason"
              fullWidth
              onChange={handleChangeTransaction}
              placeholder={t("reason")}
              multiline
              rows={3}
            />
          </Box>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <LoadingButton
              variant="outlined"
              color="error"
              onClick={handleCloseDialog}
            >
              {t("cancel")}
            </LoadingButton>
            <LoadingButton
              variant="outlined"
              color="success"
              loading={loading}
              onClick={handleCreate}
            >
              {t("confirm")}
            </LoadingButton>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CreateTransactionDialog;
