import { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Autorenew, DownloadOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

import apis from "../../apis";
import MainCard from "../../components/MainCard";
import PageTitle from "../../components/PageTitle";
import DatePickerRange from "../../components/PickDateRange";
import StatementList from "./StatementList";
import { WALLET_TYPE } from "../../constants/wallet";
import { ALL, INITIAL_PAGING, PAGE_TYPE } from "../../constants";
import { downloadFile } from "../../utils/download";
import useSearchParams from "../../hooks/useSearchParams";

const INIT_FILTER = {
  walletType: ALL,
  userId: null,
  startDate: dayjs().startOf("month"),
  endDate: dayjs().endOf("month"),
};

const Statement = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const accessToken = useSelector((state) => state.auth.accessToken);

  const [filter, setFilter] = useState(INIT_FILTER);
  const [paging, setPaging] = useState(INITIAL_PAGING);
  const [loading, setLoading] = useState(false);
  const [statements, setStatements] = useState([]);
  const [distributors, setDistributors] = useState([]);
  const [downloading, setDownloading] = useState(false);

  const { addParams } = useSearchParams();

  const renderDistributorOptions = (id) => {
    const distributor = distributors.find((d) => d.id === id);
    if (!distributor) return "";
    return `${distributor.code}. ${
      distributor.metadata?.name || distributor.name
    }`;
  };

  const handleChangePage = (page) => {
    setPaging((prev) => ({ ...prev, page }));
    addParams({ page });
  };

  const handleChangeWalletType = (event) => {
    const { value } = event.target;
    setFilter((prev) => ({ ...prev, walletType: value }));
    addParams({ walletType: value });
  };

  const handleChangeUserId = (value) => {
    setFilter((prev) => ({ ...prev, userId: value }));
    addParams({ userId: value });
  };

  const handleChangeDate = (newValue) => {
    setFilter((prev) => ({
      ...prev,
      startDate: newValue[0],
      endDate: newValue[1],
    }));
    setPaging(INITIAL_PAGING);
    addParams({
      page: INITIAL_PAGING.page,
      startDate: dayjs(newValue[0]).format("YYYY-MM-DD"),
      endDate: dayjs(newValue[1]).format("YYYY-MM-DD"),
    });
  };

  const handleRefresh = () => {
    setFilter((prev) => ({
      ...prev,
      startDate: INIT_FILTER.startDate,
      endDate: INIT_FILTER.endDate,
    }));
    setPaging(INITIAL_PAGING);
    addParams({
      page: INITIAL_PAGING.page,
      startDate: dayjs(INIT_FILTER.startDate).format("YYYY-MM-DD"),
      endDate: dayjs(INIT_FILTER.endDate).format("YYYY-MM-DD"),
    });
  };

  const handleDownload = async () => {
    setDownloading(true);
    try {
      const searchParams = queryString.parse(location.search);
      const {
        walletType = INIT_FILTER.walletType,
        userId = INIT_FILTER.userId,
        startDate = dayjs(INIT_FILTER.startDate).format("YYYY-MM-DD"),
        endDate = dayjs(INIT_FILTER.endDate).format("YYYY-MM-DD"),
      } = searchParams;

      await downloadFile({
        url: "/statements/download",
        params: {
          walletType: walletType === ALL ? undefined : walletType,
          userId: userId || undefined,
          startTime: dayjs(startDate).startOf("day").format(),
          endTime: dayjs(endDate).endOf("day").format(),
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        fileName: `statements_${dayjs().unix()}.xlsx`,
      });
    } catch (error) {
      toast.error(t(error.message));
    }
    setDownloading(false);
  };

  const fetchDistributors = async () => {
    try {
      const { result } = await apis.user.getUsers({
        pageType: PAGE_TYPE.DISTRIBUTOR,
        sort: ["code_asc"],
      });
      setDistributors(result);
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const fetchStatements = async (newFilter) => {
    setLoading(true);
    try {
      const condition = {
        limit: paging.limit,
        page: newFilter.page,
        userId: newFilter.userId || undefined,
        walletType:
          newFilter.walletType === ALL ? undefined : newFilter.walletType,
        startTime: dayjs(newFilter.startDate).startOf("day").format(),
        endTime: dayjs(newFilter.endDate).endOf("day").format(),
      };

      const { result } = await apis.statement.getStatements(condition);
      setStatements(result.statements);
      setPaging((prev) => ({ ...prev, total: result.total }));
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  useEffect(() => {
    const searchParams = queryString.parse(location.search);
    const {
      page = INITIAL_PAGING.page,
      walletType = INIT_FILTER.walletType,
      userId = INIT_FILTER.userId,
      startDate = dayjs(INIT_FILTER.startDate).format("YYYY-MM-DD"),
      endDate = dayjs(INIT_FILTER.endDate).format("YYYY-MM-DD"),
    } = searchParams;
    setFilter({ walletType, userId, startDate, endDate });
    setPaging((prev) => ({ ...prev, page: parseInt(page, 10) }));
    fetchStatements({
      walletType,
      userId,
      page: parseInt(page, 10),
      startDate,
      endDate,
    });
  }, [location.search]);

  useEffect(() => {
    fetchDistributors();
  }, []);

  return (
    <>
      <PageTitle title={t("statement")} />
      <MainCard>
        <Grid container spacing={2} marginBottom={2}>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Autocomplete
              size="small"
              value={filter.userId}
              options={distributors.map((distributor) => distributor.id)}
              getOptionLabel={(option) => renderDistributorOptions(option)}
              filterSelectedOptions
              onChange={(event, newValue) => handleChangeUserId(newValue)}
              renderOption={(props, key) => (
                <MenuItem value={key} {...props}>
                  {renderDistributorOptions(key)}
                </MenuItem>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  placeholder={t("distributor")}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2} lg={2}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                {t("walletType")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                value={filter.walletType}
                onChange={handleChangeWalletType}
                size="small"
                label={t("walletType")}
                fullWidth
              >
                <MenuItem value={ALL}>{t("all")}</MenuItem>
                <MenuItem value={WALLET_TYPE.PAYMENT}>
                  {t("paymentWallet")}
                </MenuItem>
                <MenuItem value={WALLET_TYPE.COMMODITY_COMPENSATION}>
                  {t("commodityCompensationWallet")}
                </MenuItem>
                <MenuItem value={WALLET_TYPE.FREIGHT_COMPENSATION}>
                  {t("freightCompensationWallet")}
                </MenuItem>
                <MenuItem value={WALLET_TYPE.BONUS}>
                  {t("bonusWallet")}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={8} md={7} lg={5}>
            <Box display="flex">
              <DatePickerRange
                value={[filter.startDate, filter.endDate]}
                onChange={handleChangeDate}
              />
              <Tooltip title={t("refresh")}>
                <IconButton aria-label="refresh" onClick={handleRefresh}>
                  <Autorenew />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} md={12} lg={2}>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            >
              <LoadingButton
                loading={downloading}
                loadingPosition="start"
                variant="outlined"
                startIcon={<DownloadOutlined />}
                disabled={!statements.length}
                onClick={handleDownload}
              >
                {t("download")}
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
        <StatementList
          loading={loading}
          paging={paging}
          handleChangePage={handleChangePage}
          statements={statements}
          distributors={distributors}
        />
      </MainCard>
    </>
  );
};

export default Statement;
