import { SERVICE } from "../constants";
import api from "./api";

const getDistributorOrders = async (params) => {
  const res = await api({
    method: "GET",
    url: `/distributor-orders`,
    params,
    source: SERVICE.VELA_API,
  });
  return res;
};

const getDistributorOrder = async (id) => {
  const res = await api({
    method: "GET",
    url: `/distributor-orders/${id}`,
    source: SERVICE.VELA_API,
  });
  return res;
};

const adminUpdateDistributorOrder = async (id, data) => {
  const res = await api({
    method: "PUT",
    url: `/distributor-orders/${id}/admin-update`,
    data,
    source: SERVICE.VELA_API,
  });
  return res;
};

const payDistributorOrder = async (id) => {
  const res = await api({
    method: "PUT",
    url: `/distributor-orders/${id}/confirm-payment`,
    source: SERVICE.VELA_API,
  });
  return res;
};

const confirmShippingOrder = async (id, data) => {
  const res = await api({
    method: "PUT",
    url: `/distributor-orders/${id}/confirm-shipping`,
    data,
    source: SERVICE.VELA_API,
  });
  return res;
};

const warehouseUpdateDistributorOrder = async (id, data) => {
  const res = await api({
    method: "PUT",
    url: `/distributor-orders/${id}/warehouse-update`,
    data,
    source: SERVICE.VELA_API,
  });
  return res;
};

const cancelOrder = async (id) => {
  const res = await api({
    method: "POST",
    url: `/distributor-orders/${id}/cancel`,
    source: SERVICE.VELA_API,
  });
  return res;
};

const adminReReviewOrder = async (id) => {
  const res = await api({
    method: "PUT",
    url: `/distributor-orders/${id}/re-review`,
    source: SERVICE.VELA_API,
  });
  return res;
};

const createDistributorOrder = async (data) => {
  const res = await api({
    method: "POST",
    url: `/distributor-orders/admin-create`,
    data,
    source: SERVICE.VELA_API,
  });
  return res;
};

export {
  getDistributorOrders,
  getDistributorOrder,
  adminUpdateDistributorOrder,
  payDistributorOrder,
  confirmShippingOrder,
  warehouseUpdateDistributorOrder,
  cancelOrder,
  adminReReviewOrder,
  createDistributorOrder,
};
