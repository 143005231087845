import React, { useState, useEffect } from "react";
import queryString from "query-string";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Add, DownloadOutlined, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

import apis from "../../apis";
import { ALL, DIALOG_TYPE, INITIAL_PAGING, PAGE_TYPE } from "../../constants";
import PageTitle from "../../components/PageTitle";
import MainCard from "../../components/MainCard";
import useSearchParams from "../../hooks/useSearchParams";
import debounce from "../../utils/debounce";
import UserList from "./UserList";
import UserDialog from "./UserDialog";
import QrDialog from "../../components/QrDialog";
import { QR_TYPE } from "../../constants/qr";
import Popup from "../../components/Popup";
import { downloadFile } from "../../utils/download";

const INIT_USER = {
  name: "",
  phoneNumber: "",
  password: "",
  roleId: "",
  email: "",
  citizenIdentification: "",
  province: "",
  district: "",
  ward: "",
  addressDetail: "",
};

const INIT_FILTER = {
  search: "",
  roleId: ALL,
};

const User = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const accessToken = useSelector((state) => state.auth.accessToken);

  const [filter, setFilter] = useState(INIT_FILTER);
  const [paging, setPaging] = useState(INITIAL_PAGING);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState(INIT_USER);
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [reload, setReload] = useState(false);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dialogType, setDialogType] = useState();
  const [openQrDialog, setOpenQrDialog] = useState(false);
  const [qrType, setQrType] = useState(null);
  const [provinces, setProvinces] = useState([]);
  const [openChangeStatusPopup, setOpenChangeStatusPopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [downloading, setDownloading] = useState(false);

  const { addParams } = useSearchParams();

  const handleChangePage = (page) => {
    setPaging((prev) => ({ ...prev, page }));
    addParams({ page });
  };

  const onChangeSearch = (value) => {
    setPaging((prev) => ({ ...prev, page: INITIAL_PAGING.page }));
    addParams({ search: value, page: INITIAL_PAGING.page });
  };

  const handleChangeSearch = (event) => {
    const { value } = event.target;
    setFilter((prev) => ({ ...prev, search: value }));
    debounce(onChangeSearch, 1000)(value);
  };

  const handleChangeRoleId = (event) => {
    const { value } = event.target;
    setFilter((prev) => ({ ...prev, roleId: value }));
    setPaging((prev) => ({ ...prev, page: INITIAL_PAGING.page }));
    addParams({
      roleId: value === ALL ? "" : value,
      page: INITIAL_PAGING.page,
    });
  };

  const handleOpenUserDialog = (dialogType, currUser = INIT_USER) => {
    handleClose();
    setDialogType(dialogType);

    const u = Object.keys(INIT_USER).reduce((acc, key) => {
      acc[key] = currUser[key] || "";
      return acc;
    }, {});
    if (dialogType === DIALOG_TYPE.UPDATE) {
      u.id = currUser.id;
      u.roleType = currUser.role?.type;
      u.roleId = currUser.role?.id;
      u.name = currUser.metadata?.name || currUser.name;
      u.email = currUser.metadata?.email || "";
      u.citizenIdentification = currUser.metadata?.citizenIdentification || "";
      u.province = currUser.metadata?.address?.province || "";
      u.district = currUser.metadata?.address?.district || "";
      u.ward = currUser.metadata?.address?.district || "";
      u.addressDetail = currUser.metadata?.address?.addressDetail || "";
    }
    setUser(u);
    setOpenUserDialog(true);
  };

  const handleCloseUserDialog = () => {
    setOpenUserDialog(false);
    setUser(INIT_USER);
  };

  const handleOpenChangeStatusPopup = (currUser) => {
    setUser(currUser);
    setOpenChangeStatusPopup(true);
  };

  const handleCloseChangeStatusPopup = () => {
    setOpenChangeStatusPopup(false);
  };

  const handleOpenQrDialog = (type) => {
    handleClose();
    setQrType(type);
    setOpenQrDialog(true);
  };

  const handleCloseQrDialog = () => {
    setOpenQrDialog(false);
    setQrType(null);
  };

  const handleReload = () => {
    setReload((prev) => !prev);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownload = async () => {
    setDownloading(true);
    try {
      const searchParams = queryString.parse(location.search);
      const { search = INIT_FILTER.search, roleId = INIT_FILTER.roleId } =
        searchParams;

      await downloadFile({
        url: "/users/download",
        params: {
          search: search || undefined,
          roleId: roleId === ALL ? undefined : roleId,
          pageType: roleId ? undefined : PAGE_TYPE.USER,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        fileName: `users_${dayjs().unix()}.xlsx`,
      });
    } catch (error) {
      toast.error(t(error.message));
    }

    setDownloading(false);
  };

  const fetchUsers = async (newFilter) => {
    setLoading(true);
    try {
      const condition = {
        search: newFilter.search || undefined,
        roleId: newFilter.roleId === ALL ? undefined : newFilter.roleId,
        limit: paging.limit,
        page: newFilter.page,
      };

      if (!condition.roleId) {
        condition.pageType = PAGE_TYPE.USER;
      }

      const { result } = await apis.user.getUsers(condition);
      setUsers(result.users);
      setPaging((prev) => ({ ...prev, total: result.total }));
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  const fetchRoles = async () => {
    try {
      const res = await apis.role.getRoles({ pageType: PAGE_TYPE.USER });
      const { result = [] } = res;
      setRoles(result);
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const fetchProvinces = async () => {
    try {
      const res = await apis.address.getVnUnits();
      const { result = [] } = res;
      setProvinces(result);
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const handleChangeStatus = async () => {
    try {
      const res = await apis.user.changeStatus(user.id, !user.active);
      if (!res) throw new Error();
      toast.success(
        user.active ? t("lockAccountSuccess") : t("unlockAccountSuccess")
      );
      handleReload();
    } catch (error) {
      toast.error(t(error.message));
    }
    setUser(INIT_USER);
  };

  useEffect(() => {
    fetchRoles();
    fetchProvinces();
  }, []);

  useEffect(() => {
    const searchParams = queryString.parse(location.search);
    const {
      search = INIT_FILTER.search,
      roleId = INIT_FILTER.roleId,
      page = INITIAL_PAGING.page,
    } = searchParams;
    setFilter({ search, roleId });
    setPaging((prev) => ({ ...prev, page: parseInt(page, 10) }));
    fetchUsers({ search, roleId, page: parseInt(page, 10) });
  }, [location.search, reload]);

  return (
    <>
      <PageTitle title={t("user")} />
      <MainCard>
        <Grid container spacing={2} marginBottom={2}>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <TextField
              value={filter.search}
              size="small"
              fullWidth
              placeholder={t("userSearch")}
              onChange={handleChangeSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={2}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">{t("role")}</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                value={filter.roleId}
                onChange={handleChangeRoleId}
                size="small"
                label="Vai trò"
                fullWidth
              >
                <MenuItem value={ALL}>{t("all")}</MenuItem>
                {roles.map((role) => (
                  <MenuItem key={role.id} value={role.id}>
                    {role.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={6}>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            >
              <Box>
                <Button
                  id="basic-button"
                  aria-controls={!!anchorEl ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={!!anchorEl ? "true" : undefined}
                  onClick={handleClick}
                  startIcon={<Add />}
                  variant="contained"
                >
                  {t("addNew")}
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={!!anchorEl}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem
                    onClick={() => handleOpenUserDialog(DIALOG_TYPE.CREATE)}
                  >
                    {t("addUser")}
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleOpenQrDialog(QR_TYPE.CREATE_ASM)}
                  >
                    {t("addAsm")}
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      handleOpenQrDialog(QR_TYPE.CREATE_SUPERVISOR)
                    }
                  >
                    {t("addSupervisor")}
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      handleOpenQrDialog(QR_TYPE.CREATE_BUSINESS_DIRECTOR)
                    }
                  >
                    {t("addBusinessDirector")}
                  </MenuItem>
                </Menu>
              </Box>
              <LoadingButton
                loading={downloading}
                loadingPosition="start"
                variant="outlined"
                startIcon={<DownloadOutlined />}
                disabled={!users.length}
                onClick={handleDownload}
              >
                {t("download")}
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
        <UserList
          loading={loading}
          paging={paging}
          handleChangePage={handleChangePage}
          users={users}
          handleOpenUserDialog={handleOpenUserDialog}
          handleOpenChangeStatusPopup={handleOpenChangeStatusPopup}
        />
      </MainCard>
      <UserDialog
        open={openUserDialog}
        handleClose={handleCloseUserDialog}
        initUser={user}
        handleReload={handleReload}
        roles={roles}
        provinces={provinces}
        dialogType={dialogType}
      />
      <QrDialog
        open={openQrDialog}
        handleClose={handleCloseQrDialog}
        qrType={qrType}
      />
      <Popup
        open={openChangeStatusPopup}
        onClose={handleCloseChangeStatusPopup}
        onOk={handleChangeStatus}
        okMessage={t("accept")}
        content={
          user.active
            ? t("areYouSureLockAccount", {
                name: user.name,
                phoneNumber: user.phoneNumber,
              })
            : t("areYouSureUnlockAccount", {
                name: user.name,
                phoneNumber: user.phoneNumber,
              })
        }
        title={user.active ? t("lockAccount") : t("unlockAccount")}
      />
    </>
  );
};

export default User;
