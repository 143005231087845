import { SERVICE } from "../constants";
import api from "./api";

const getGroupTransactions = async (params) => {
  const response = await api({
    method: "GET",
    url: `/group-transactions`,
    params,
    source: SERVICE.VELA_API,
  });

  return response;
};

const getGroupTransaction = async (groupTransactionId) => {
  const response = await api({
    method: "GET",
    url: `/group-transactions/${groupTransactionId}`,
    source: SERVICE.VELA_API,
  });

  return response;
};

const reCalculate = async (groupTransactionId) => {
  const response = await api({
    method: "PUT",
    url: `/group-transactions/${groupTransactionId}/re-calculate`,
    source: SERVICE.VELA_API,
  });

  return response;
};

export { getGroupTransactions, getGroupTransaction, reCalculate };
