import { useEffect, useState } from "react";
import queryString from "query-string";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { Search } from "@mui/icons-material";

import apis from "../../apis";
import MainCard from "../../components/MainCard";
import PageTitle from "../../components/PageTitle";
import { ALL, INITIAL_PAGING } from "../../constants";
import { TRANSACTION_STATUS, WALLET_TYPE } from "../../constants/wallet";
import useSearchParams from "../../hooks/useSearchParams";
import GroupTransactionList from "./GroupTransactionList";
import debounce from "../../utils/debounce";

const INIT_FILTER = {
  search: "",
  status: ALL,
};

const Bonus = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const [filter, setFilter] = useState(INIT_FILTER);
  const [paging, setPaging] = useState(INITIAL_PAGING);
  const [loading, setLoading] = useState(false);
  const [groupTransactions, setGroupTransactions] = useState([]);

  const { addParams } = useSearchParams();

  const handleChangePage = (page) => {
    setPaging((prev) => ({ ...prev, page }));
    addParams({ page });
  };

  const fetchGroupTransactions = async (newFilter) => {
    setLoading(true);
    try {
      const condition = {
        limit: paging.limit,
        page: newFilter.page,
        search: newFilter.search,
        status: newFilter.status === ALL ? undefined : newFilter.status,
        walletType: WALLET_TYPE.BONUS,
      };

      const { result } = await apis.groupTransaction.getGroupTransactions(
        condition
      );
      setGroupTransactions(result.groupTransactions);
      setPaging((prev) => ({ ...prev, total: result.total }));
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  const onChangeSearch = (value) => {
    setPaging((prev) => ({ ...prev, page: INITIAL_PAGING.page }));
    addParams({ search: value, page: INITIAL_PAGING.page });
  };

  const handleChangeSearch = (event) => {
    const { value } = event.target;
    setFilter((prev) => ({ ...prev, search: value }));
    debounce(onChangeSearch, 1000)(value);
  };

  const handleChangeStatus = (event) => {
    const { value } = event.target;
    setFilter((prev) => ({ ...prev, status: value }));
    setPaging(INITIAL_PAGING);
    addParams({ status: value });
  };

  useEffect(() => {
    const searchParams = queryString.parse(location.search);
    const {
      page = INITIAL_PAGING.page,
      search = INIT_FILTER.search,
      status = INIT_FILTER.status,
    } = searchParams;
    setFilter({ search, status });
    setPaging((prev) => ({ ...prev, page: parseInt(page, 10) }));
    fetchGroupTransactions({ search, status });
  }, [location.search]);

  return (
    <>
      <PageTitle title={t("bonus")} />
      <MainCard>
        <Grid container spacing={2} marginBottom={2}>
          <Grid item xs={12} sm={8} md={6} lg={4}>
            <TextField
              value={filter.search}
              size="small"
              fullWidth
              placeholder={t("searchTitle")}
              onChange={handleChangeSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={2}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                {t("status")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                value={filter.status}
                onChange={handleChangeStatus}
                size="small"
                label={t("status")}
                fullWidth
              >
                <MenuItem value={ALL}>{t("all")}</MenuItem>
                {[TRANSACTION_STATUS.PENDING, TRANSACTION_STATUS.COMPLETED].map(
                  (status) => (
                    <MenuItem key={status} value={status}>
                      {t(status)}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            ></Stack>
          </Grid>
        </Grid>
        <GroupTransactionList
          loading={loading}
          paging={paging}
          handleChangePage={handleChangePage}
          groupTransactions={groupTransactions}
        />
      </MainCard>
    </>
  );
};

export default Bonus;
