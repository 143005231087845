import React from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import dayjs from "dayjs";

const Invoice = React.forwardRef(({ order, distributor }, ref) => {
  return (
    <div ref={ref} style={{ width: "100%", heigh: window.innerHeight }}>
      <Box>
        <Typography
          align="center"
          fontWeight={700}
          fontSize={18}
          sx={{ textTransform: "uppercase" }}
          paddingBottom={3}
        >
          Vela Foods
        </Typography>
        <Divider />
        <Typography
          align="center"
          fontWeight={700}
          fontSize={20}
          textTransform="uppercase"
          paddingY={3}
        >
          Phiếu xuất hàng
        </Typography>
        <Box sx={{ flexGrow: 1 }} mb={3}>
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <Typography>Khách hàng:</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography fontWeight={600} textTransform="uppercase">
                    {distributor?.metadata?.name || distributor?.name}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Typography>Số điện thoại:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography fontWeight={600}>
                    {distributor?.phoneNumber}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={7}>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <Typography>Địa chỉ:</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography fontWeight={600}>
                    {[
                      distributor?.metadata?.address?.addressDetail,
                      distributor?.metadata?.address?.ward,
                      distributor?.metadata?.address?.district,
                      distributor?.metadata?.address?.province,
                    ]
                      .filter((item) => item)
                      .join(", ")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Typography>Mã khách hàng:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography fontWeight={600}>{distributor?.code}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={7}>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <Typography>Ngày giao hàng:</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography fontWeight={600}>
                    {dayjs(order?.startShippingAt).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Typography>Mã đơn hàng:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography fontWeight={600}>
                    {order?.distributorOrderId}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ flexGrow: 1 }} mb={3}>
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              border: "none",
            }}
          >
<thead>
  <tr>
    <th style={{ border: "1px solid #333333", padding: "5px", fontSize: "14px" }}>
      STT
    </th>
    <th style={{ border: "1px solid #333333", padding: "5px", fontSize: "14px" }}>
      Tên sản phẩm
    </th>
    <th style={{ border: "1px solid #333333", padding: "5px", fontSize: "14px" }}>
      Đơn vị
    </th>
    <th style={{ border: "1px solid #333333", padding: "5px", fontSize: "14px" }}>
      Thực xuất
    </th>
  </tr>
</thead>
<tbody>
  {order?.items?.map((item, index) => (
    <tr key={item.productId}>
      <td
        style={{
          textAlign: "center",
          border: "1px solid #333333",
          padding: "5px",
          fontSize: "12px"
        }}
      >
        {index + 1}
      </td>
      <td
        style={{
          textAlign: "left",
          border: "1px solid #333333",
          padding: "5px",
          fontSize: "12px"
        }}
      >
        {item.name}
      </td>
      <td
        style={{
          textAlign: "center",
          border: "1px solid #333333",
          padding: "5px",
          fontSize: "12px"
        }}
      >
        Thùng
      </td>
      <td
        style={{
          textAlign: "center",
          border: "1px solid #333333",
          padding: "5px",
          fontSize: "12px"
        }}
      >
        {item.quantity}
      </td>
    </tr>
  ))}
  <tr>
    <td
      colSpan={3}
      style={{
        textAlign: "center",
        border: "1px solid #333333",
        padding: "5px",
        fontWeight: 600,
        fontSize: "12px"
      }}
    >
      Tổng
    </td>
    <td
      style={{
        textAlign: "center",
        border: "1px solid #333333",
        padding: "5px",
        fontWeight: 600,
        fontSize: "12px"
      }}
    >
      {order?.items?.reduce((acc, item) => acc + item.quantity, 0)}
    </td>
  </tr>
</tbody>
          </table>
        </Box>
        <Box sx={{ flexGrow: 1 }} mb={3}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography align="center">Người nhận</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography align="center">Người giao</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography align="center">Kế toán</Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
});

export default Invoice;
