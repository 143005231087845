import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import {
  DoNotDisturb,
  LockOpenOutlined,
  LockOutlined,
  SocialDistance,
} from "@mui/icons-material";
import { useHistory } from "react-router-dom";

import Table from "../../components/Table";
import { COLOR } from "../../styles/color";
import TransferDistributorDialog from "./TransferDistributorDialog";
import ROUTE from "../../constants/route";

const DistributorList = ({
  loading,
  paging,
  handleChangePage,
  distributors,
  asms,
  supervisors,
  handleOpenChangeStatusPopup,
  handleOpenStopCooperatePopup,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [distributor, setDistributor] = useState({});
  const [openTransferDistributorDialog, setOpenTransferDistributorDialog] =
    useState(false);

  const renderAsm = (region) => {
    const asm = asms.find((user) => user.region === region);
    if (!asm) return "-";
    return `${asm.metadata?.name || asm.name} - ${asm.phoneNumber}`;
  };

  const renderSupervisor = (id) => {
    const supervisor = supervisors.find((user) => user.id === id);
    if (!supervisor) return "-";
    return `${supervisor.metadata?.name || supervisor.name} - ${
      supervisor.phoneNumber
    }`;
  };

  const handleOpenTransferDistributorDialog = (row) => {
    setDistributor(row);
    setOpenTransferDistributorDialog(true);
  };

  const handleCloseTransferDistributorDialog = () => {
    setOpenTransferDistributorDialog(false);
    setDistributor({});
  };

  const handleClickRow = (row) =>
    history.push(`${ROUTE.DISTRIBUTOR}/${row.id}`);

  const renderStatus = (row) => {
    if (row.stopCooperatedAt)
      return (
        <Typography sx={{ color: COLOR.secondary.base }}>
          {t("stopCooperate")}
        </Typography>
      );
    if (row.active)
      return (
        <Typography sx={{ color: COLOR.success.base }}>
          {t("active")}
        </Typography>
      );
    return (
      <Typography sx={{ color: COLOR.error.base }}>{t("inactive")}</Typography>
    );
  };

  const columns = [
    {
      title: t("code"),
      align: "left",
      field: "code",
    },
    {
      title: t("fullName"),
      align: "left",
      field: "name",
      sortable: true,
      render: (row) => row.metadata?.name || row.name,
    },

    {
      title: t("phoneNumber"),
      align: "left",
      field: "phoneNumber",
      sortable: true,
    },
    {
      title: "ASM",
      align: "left",
      render: (row) => renderAsm(row.region),
    },
    {
      title: t("supervisor"),
      align: "left",
      render: (row) => renderSupervisor(row.supervisorId),
    },
    {
      title: t("region"),
      align: "left",
      render: (row) => `${t("region")} ${row.region}`,
      field: "region",
      sortable: true,
    },
    {
      title: t("address"),
      align: "left",
      render: (row) =>
        [
          row.metadata?.address?.addressDetail,
          row.metadata?.address?.ward,
          row.metadata?.address?.district,
          row.metadata?.address?.province,
        ]
          .filter((item) => item)
          .join(", "),
    },
    {
      title: t("status"),
      align: "left",
      render: (row) => renderStatus(row),
    },
    {
      title: "...",
      align: "center",
      render: (row) => (
        <>
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Tooltip title={t("handoverOfWork")}>
              <span>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOpenTransferDistributorDialog(row);
                  }}
                  color="info"
                  disabled={!!row.stopCooperatedAt}
                >
                  <SocialDistance />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={row.active ? t("lock") : t("unlock")}>
              <span>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOpenChangeStatusPopup(row);
                  }}
                  color={row.active ? "error" : "success"}
                  disabled={!!row.stopCooperatedAt}
                >
                  {row.active ? <LockOutlined /> : <LockOpenOutlined />}
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={t("stopCooperate")}>
              <span>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOpenStopCooperatePopup(row);
                  }}
                  color="warning"
                  disabled={!!row.stopCooperatedAt}
                >
                  <DoNotDisturb />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        data={distributors}
        columns={columns}
        total={paging.total}
        page={paging.page}
        loading={loading}
        onChangePage={handleChangePage}
        showNumber={true}
        onRowClick={handleClickRow}
      />
      <TransferDistributorDialog
        open={openTransferDistributorDialog}
        handleClose={handleCloseTransferDistributorDialog}
        distributor={distributor}
      />
    </>
  );
};

export default DistributorList;
