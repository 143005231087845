import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";

import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import { TOP_BAR_HEIGHT } from "../../styles/config";
import { NOTIFICATION_URL } from "../../configs";
import { PING_INTERVAL, WS_TYPE } from "../../constants/ws";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { addNotifications } from "../../stores/notificationSlice";
import apis from "../../apis";
import { NOTIFICATION_STATUS } from "../../constants/notification";

const Layout = ({ children }) => {
  const [openMobileSidebar, setOpenMobileSidebar] = useState(false);
  const [openMiniSidebar, setOpenMiniSidebar] = useState(false);

  const accessToken = useSelector((state) => state.auth.accessToken);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const ws = useRef(null);

  const onMobileSidebarToggle = () => {
    setOpenMobileSidebar((prev) => !prev);
  };

  const fetchNotifications = async () => {
    try {
      const res = await apis.notification.getNotifications({
        status: NOTIFICATION_STATUS.NEW,
        page: 1,
        limit: 10,
      });
      dispatch(addNotifications(res.result?.notifications));
    } catch (error) {
      console.error(error);
    }
  };

  const getNotificationMessage = (notification) => {
    const { type, data } = notification;
    const { order, distributor } = data || {};
    const distributorName = distributor?.metadata?.name || "";
    const totalBoxes = order.items?.reduce(
      (acc, item) => acc + item.quantity,
      0
    );
    return t(`notification.${type}`, { totalBoxes, distributorName });
  };

  useEffect(() => {
    // Connect to WebSocket
    ws.current = new WebSocket(NOTIFICATION_URL);
    let pingInterval;
    ws.current.onopen = () => {
      ws.current.send(JSON.stringify({ type: WS_TYPE.INIT, accessToken }));

      pingInterval = setInterval(() => {
        ws.current.send(JSON.stringify({ type: WS_TYPE.PING }));
      }, PING_INTERVAL);
    };

    ws.current.onmessage = (res) => {
      const { type, message } = JSON.parse(res.data);
      switch (type) {
        case WS_TYPE.PONG:
          break;
        case WS_TYPE.NOTIFICATION:
          dispatch(addNotifications([message]));
          toast.success(getNotificationMessage(message));
          break;
        default:
          break;
      }
    };

    ws.current.onclose = (event) => {
      clearInterval(pingInterval);
    };

    return () => {
      ws.current.close();
    };
  }, []);

  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <Box display="flex">
      <Sidebar
        openMiniSidebar={openMiniSidebar}
        openMobileSidebar={openMobileSidebar}
        onMobileSidebarToggle={onMobileSidebarToggle}
      />
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <Header
          onMobileSidebarToggle={onMobileSidebarToggle}
          openMiniSidebar={openMiniSidebar}
          setOpenMiniSidebar={setOpenMiniSidebar}
        />
        <Box
          component="main"
          sx={{
            height: `calc(100vh - ${TOP_BAR_HEIGHT + 1}px)`,
            overflowY: "auto",
          }}
        >
          <Box padding={2}>{children}</Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
