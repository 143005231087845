import { useTranslation } from "react-i18next";
import { Stack, Typography } from "@mui/material";

import Table from "../../components/Table";
import { COLOR } from "../../styles/color";

const StoreList = ({
  loading,
  paging,
  handleChangePage,
  stores,
  distributors,
}) => {
  const { t } = useTranslation();

  const renderDistributor = (store) => {
    const { saleId } = store;
    const distributor = distributors.find((d) => d.staffIds.includes(saleId));
    if (distributor)
      return `${distributor.metadata?.name} - ${distributor.phoneNumber}`;

    return "-";
  };

  const columns = [
    {
      title: t("storeName"),
      align: "left",
      field: "name",
      sortable: true,
    },
    {
      title: t("region"),
      align: "left",
      render: (row) => `${t("region")} ${row.region}`,
      field: "region",
      sortable: true,
    },
    {
      title: t("address"),
      align: "left",
      render: (row) =>
        `${row.address}, ${row.ward}, ${row.district}, ${row.province}`,
      field: "addressDetail",
      sortable: true,
    },
    {
      title: t("sale"),
      align: "left",
      render: (row) =>
        `${row?.salePerson[0]?.name} - ${row?.salePerson[0]?.phoneNumber}`,
    },
    {
      title: t("distributor"),
      align: "left",
      render: (row) => renderDistributor(row),
    },
    {
      title: t("status"),
      align: "left",
      render: (row) => (
        <>
          {row.isVerified ? (
            <Typography sx={{ color: COLOR.success.base }}>
              {t("verified")}
            </Typography>
          ) : (
            <Typography sx={{ color: COLOR.error.base }}>
              {t("unverified")}
            </Typography>
          )}
        </>
      ),
    },
    {
      title: "",
      align: "right",
      render: (row) => (
        <>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
          ></Stack>
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        data={stores}
        columns={columns}
        total={paging.total}
        page={paging.page}
        loading={loading}
        onChangePage={handleChangePage}
        showNumber={true}
      />
    </>
  );
};

export default StoreList;
