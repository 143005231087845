import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  CircularProgress,
  Input,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  Autorenew,
  CalculateOutlined,
  SaveOutlined,
} from "@mui/icons-material";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

import apis from "../../apis";
import PageTitle from "../../components/PageTitle";
import ROUTE from "../../constants/route";
import MainCard from "../../components/MainCard";
import CurrencyInput from "../../components/CurrencyInput";
import { StyledTable } from "./index.style";
import { TRANSACTION_STATUS } from "../../constants/wallet";
import NoData from "../../components/NoData";
import { validateRequired } from "../../utils/validate";
import { COLOR } from "../../styles/color";

const INIT_TRANSACTION_ERROR = {
  amount: "",
};

const FreightCompensationDetail = () => {
  const { t } = useTranslation();
  const { freightCompensationId } = useParams();

  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [originalTransactions, setOriginalTransactions] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [transactionsError, setTransactionsError] = useState([]);
  const [error, setError] = useState(false);
  const [groupTransaction, setGroupTransaction] = useState({});
  const [breadcrumbs, setBreadcrumbs] = useState([
    { title: t("freightCompensation"), path: ROUTE.FREIGHT_COMPENSATION },
  ]);
  const [calculating, setCalculating] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const [indexString, field] = name.split(".");
    const index = parseInt(indexString, 10);
    const newTransaction = { ...transactions[index], [field]: value };
    const newTransactions = [...transactions];
    newTransactions[index] = newTransaction;
    setTransactions(newTransactions);

    let newTransactionError = { ...transactionsError[index], [field]: false };
    const newTransactionsError = [...transactionsError];
    newTransactionsError[index] = newTransactionError;
    setTransactionsError(newTransactionsError);
  };

  const validateItem = (event, initName) => {
    const name = event ? event.target.name : initName;
    const [indexString, field] = name.split(".");
    const index = parseInt(indexString, 10);
    const value = transactions[index][field];
    let isError = false;
    if (!validateRequired(value)) {
      isError = true;
    }

    const newTransactionsError = [...transactionsError];
    newTransactionsError[index] = {
      ...transactionsError[index],
      [field]: isError,
    };
    setTransactionsError(newTransactionsError);

    return !isError;
  };

  const validate = () =>
    transactions.every((_, index) => validateItem(null, index + ".amount"));

  const handleReset = () => {
    setTransactions(originalTransactions);
    setTransactionsError(
      originalTransactions.map(() => INIT_TRANSACTION_ERROR)
    );
  };

  const renderTitle = (groupTransaction) => {
    const { month, quarter, year } = groupTransaction;
    if (quarter) return `${t("quarter")} ${quarter}/${year}`;
    if (month) return `${t("month")} ${month}/${year}`;
    return `${t("year")} ${year}`;
  };

  const handleApprove = async () => {
    if (!validate()) {
      toast.error(t("dataInvalid"));
      return;
    }

    setUpdating(true);
    try {
      await apis.transaction.approveFreightCompensation({
        groupTransactionId: freightCompensationId,
        transactions: transactions.map(({ id: transactionId, amount }) => ({
          transactionId,
          amount,
        })),
      });
      getGroupTransaction();
      toast.success(t("approveFreightCompensationSuccess"));
    } catch (error) {
      toast.error(t(error.message));
    }
    setUpdating(false);
  };

  const handleReCalculate = async () => {
    setCalculating(true);
    try {
      await apis.groupTransaction.reCalculate(freightCompensationId);
      getGroupTransaction();
      toast.success(t("reCalculateFreightCompensationSuccess"));
    } catch (error) {
      toast.error(t(error.message));
    }
    setCalculating(false);
  };

  const getTransactions = async () => {
    try {
      const condition = {
        groupTransactionId: freightCompensationId,
      };

      const { result = [] } = await apis.transaction.getTransactions(condition);
      setOriginalTransactions(result);
      setTransactions(result);
      setTransactionsError(result.map(() => INIT_TRANSACTION_ERROR));
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const getGroupTransaction = async () => {
    try {
      const { result } = await apis.groupTransaction.getGroupTransaction(
        freightCompensationId
      );
      setGroupTransaction(result);
      setBreadcrumbs((prev) => [prev[0], { title: renderTitle(result) }]);
      await getTransactions();
    } catch (error) {
      toast.error(t(error.message));
      setError(true);
      setBreadcrumbs([{ title: t("back"), path: ROUTE.FREIGHT_COMPENSATION }]);
    }
  };

  const init = async () => {
    setLoading(true);
    await getGroupTransaction();
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  const isCompleted = useMemo(
    () => groupTransaction.status === TRANSACTION_STATUS.COMPLETED,
    [groupTransaction]
  );

  const renderTransactionStatus = (status) => {
    switch (status) {
      case TRANSACTION_STATUS.PENDING:
        return (
          <Typography
            fontWeight={600}
            fontSize="18px"
            color={COLOR.warning.base}
          >
            {t(status)}
          </Typography>
        );
      case TRANSACTION_STATUS.COMPLETED:
        return (
          <Typography
            fontWeight={600}
            fontSize="18px"
            color={COLOR.success.base}
          >
            {t(status)}
          </Typography>
        );
      default:
        return (
          <Typography fontWeight={600} fontSize="18px">
            {t(status)}
          </Typography>
        );
    }
  };

  const renderContent = () => (
    <>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        mb={2}
      >
        <Typography fontWeight={500} fontSize="18px">
          {t("status")}:
        </Typography>
        {renderTransactionStatus(groupTransaction.status)}
      </Stack>
      <StyledTable>
        <Table className="table">
          <TableHead className="table-header">
            <TableRow>
              <TableCell className="header-cell cell-no" align="center">
                {t("no")}
              </TableCell>
              <TableCell
                className="header-cell cell-distributor"
                align="center"
              >
                {t("distributor")}
              </TableCell>
              <TableCell className="header-cell" align="center">
                {t("phoneNumber")}
              </TableCell>
              <TableCell className="header-cell cell-date" align="center">
                {t("createdAt")}
              </TableCell>
              <TableCell className="header-cell" align="center">
                {t("totalMoney")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="table-body">
            {!!transactions.length &&
              transactions.map((transaction, index) => (
                <TableRow
                  key={index}
                  className="body-row"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell className="body-cell" align="center">
                    {index + 1}
                  </TableCell>
                  <TableCell className="body-cell">
                    {transaction?.user?.metadata?.name ||
                      transaction?.user?.name ||
                      "-"}
                  </TableCell>
                  <TableCell className="body-cell ">
                    {transaction?.user?.metadata?.phoneNumber ||
                      transaction?.user?.phoneNumber ||
                      "-"}
                  </TableCell>
                  <TableCell className="body-cell">
                    {dayjs(transaction.createdAt).format("YYYY-MM-DD HH:mm:ss")}
                  </TableCell>
                  <TableCell className="body-cell cell-amount">
                    <CurrencyInput
                      customInput={Input}
                      allowDecimals={false}
                      allowNegativeValue={false}
                      inputProps={{ min: 0, style: { textAlign: "center" } }}
                      value={transaction.amount}
                      variant="standard"
                      disableUnderline={!transactionsError[index]?.amount}
                      error={!!transactionsError[index]?.amount}
                      name={index + ".amount"}
                      fullWidth
                      onChange={handleChange}
                      onBlur={validateItem}
                      endAdornment={
                        <InputAdornment position="end">&#8363;</InputAdornment>
                      }
                      disabled={isCompleted}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </StyledTable>
      {!transactions.length && <NoData />}
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        mt={5}
      >
        <Button
          variant="outlined"
          color="error"
          onClick={handleReset}
          startIcon={<Autorenew />}
          disabled={!transactions.length || isCompleted}
        >
          {t("refresh")}
        </Button>
        <LoadingButton
          variant="outlined"
          loadingPosition="start"
          startIcon={<CalculateOutlined />}
          color="primary"
          onClick={handleReCalculate}
          loading={calculating}
          disabled={!transactions.length || isCompleted}
        >
          {t("reCalculate")}
        </LoadingButton>
        <LoadingButton
          variant="contained"
          loadingPosition="start"
          startIcon={<SaveOutlined />}
          color="success"
          onClick={handleApprove}
          loading={updating}
          disabled={!transactions.length || isCompleted}
        >
          {t("save")}
        </LoadingButton>
      </Stack>
    </>
  );

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs} />
      <MainCard>
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Box padding={2}>
            <Typography variant="body1" textAlign="center">
              {t("freightCompensationNotFound")} !
            </Typography>
          </Box>
        ) : (
          renderContent()
        )}
      </MainCard>
    </>
  );
};

export default FreightCompensationDetail;
